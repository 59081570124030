export default class SimpleImageButton extends Phaser.GameObjects.Container {
	constructor(scene, x, y, name, selectedName = '', atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;

		this.currentName = name;

		this.selectedName = selectedName;

		scene.add.existing(this);
		this.bg = scene.add.image(0, 0, atlas, name + '_none');

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);

		this.on('pointerover', function() {
			this.bg.setTexture('main2', this.currentName + '_active');
		});

		this.on('pointerdown', function() {
			this.bg.setTexture('main2', this.currentName + '_active');
		});

		this.on('pointerout', function() {
			this.bg.setTexture('main2', this.currentName + '_none');
		});

		this.on('pointerup', function() {
			if (this.currentName == this.btnName) {
				if (this.selectedName.length > 0) {
					this.currentName = this.selectedName;
				}
			} else {
				this.currentName = this.btnName;
			}
			this.bg.setTexture('main2', this.currentName + '_none');
		});
	}

	get isSelected() {
		return this.currentName == this.btnName;
	}

	set selected(value) {
		if (value == true) {
			this.currentName = this.btnName;
		} else {
			this.currentName = this.selectedName;
		}
		this.bg.setTexture('main2', this.currentName + '_none');
	}

	set disableBtn(value) {
		if (this._disabled == value) {
			return;
		}

		this._disabled = value;

		if (value == true) {
			this.removeInteractive();

			this.bg.setTexture('main2', this.currentName + '_disable');
		} else {
			this.setInteractive();
			this.bg.setTexture('main2', this.currentName + '_none');
		}
	}
}
