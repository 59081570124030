import Phaser from 'phaser';
import MainScene from './scenes/MainScene';
import Boot from './scenes/Boot';
import Preloader from './scenes/Preloader';
import LeaderboardPopup from './scenes/LeaderboardPopup';
import ShopPopup from './scenes/ShopPopup';
import ChooseCharacterScene from './scenes/ChooseCharacterScene';
import OfflinePopup from './scenes/OfflinePopup';
import NewEquipPopup from './scenes/NewEquipPopup';
import CurrentEquipInfoPopup from './scenes/CurrentEquipInfoPopup';
import EnhancedEquipPopup from './scenes/EnhancedEquipPopup';

import ReferralPopup from './scenes/ReferralPopup';
import CharacterInfoPopup from './scenes/CharacterInfoPopup';
import ResultPopup from './scenes/ResultPopup';
import EnhanceResultPopup from './scenes/EnhanceResultPopup';
import EarnPopup from './scenes/EarnPopup';
import MinePopup from './scenes/MinePopup';
import LockScreen from './scenes/LockScreen';
import AirdropPopup from './scenes/AirdropPopup';
import SettingPopup from './scenes/SettingPopup';
import PurchasePopup from './scenes/PurchasePopup';
import MaintainScene from './scenes/MaintainScene';
import FriendView from './scenes/FriendView';

import Alert from './scenes/Alert';

import AppController from './controller/AppController';

import WebFontLoaderPlugin from 'phaser3-rex-plugins/plugins/webfontloader-plugin.js';
import UIPlugin from 'phaser3-rex-plugins/templates/ui/ui-plugin.js';
import EaseMovePlugin from 'phaser3-rex-plugins/plugins/easemove-plugin.js';
import { SpinePlugin } from '@esotericsoftware/spine-phaser';

import eruda from 'eruda';
import axios from 'axios';

//eruda.init();

Telegram.WebApp.disableVerticalSwipes();
Telegram.WebApp.enableClosingConfirmation();

const config = {
	type: Phaser.AUTO,
	width: 720,
	height: 1280,

	scene: [Boot, Preloader, MainScene, ChooseCharacterScene, NewEquipPopup, CurrentEquipInfoPopup, EnhancedEquipPopup, ReferralPopup, MinePopup, EarnPopup, LeaderboardPopup, OfflinePopup, FriendView, SettingPopup, ShopPopup, CharacterInfoPopup, ResultPopup, AirdropPopup, PurchasePopup, EnhanceResultPopup, MaintainScene, LockScreen, Alert],
	scale: {
		// Fit to window
		mode: Phaser.Scale.FIT,
		// Center vertically and horizontally
		autoCenter: Phaser.Scale.CENTER_HORIZONTALLY,
	},
	plugins: {
		scene: [
			{ key: 'spine.SpinePlugin', plugin: spine.SpinePlugin, mapping: 'spine', sceneKey: 'spine' },
			{
				key: 'rexUI',
				plugin: UIPlugin,
				mapping: 'rexUI',
			},
		],
		global: [
			{
				key: 'rexWebFontLoader',
				plugin: WebFontLoaderPlugin,
				start: true,
			},
			{
				key: 'rexEaseMove',
				plugin: EaseMovePlugin,
				start: true,
			},

			// ...
		],
	},
};

var game = new Phaser.Game(config);
AppController.gameObj = game;

Telegram.WebApp.expand();
AppController.stopScroll();
