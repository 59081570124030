import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import ItemStatRow from '../components/ItemStatRow';
import { ScrollablePanel } from 'phaser3-rex-plugins/templates/ui/ui-components.js';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';

export default class ShopPopup extends Phaser.Scene {
	constructor() {
		super('ShopPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'ShopPopup' });
	}

	async init(data) {
		console.log(data);

		//this.compareData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 650, 1050, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Shop', AppController.getText(50)).setOrigin(0.5, 0.5);

		//var container = this.add.container();

		this.scene.launch('LockScreen');
		const shopItemData = await AppController.getInappProducts();
		this.scene.stop('LockScreen');

		var container = this.rexUI.add.sizer({
			orientation: 1,
			space: {
				item: 20,
			},
		});

		var titleContainer1 = this.add.container();
		var titleSpecialOfferBg = this.add.nineslice(0, 0, 'main2', 'shop_title_bg', 660, 0, 12, 12, 0, 0).setOrigin(0.5, 0.5);
		titleContainer1.add(titleSpecialOfferBg);
		var titleSpecialOfferTitle = this.add.text(titleSpecialOfferBg.x, titleSpecialOfferBg.y, 'Special Offer', AppController.getText(50)).setOrigin(0.5, 0.5);
		titleContainer1.add(titleSpecialOfferTitle);
		titleContainer1.setSize(titleSpecialOfferBg.width, titleSpecialOfferBg.height);
		container.add(titleContainer1);

		if (this.scene.get('MainScene').myMineLevel[0] >= 6 && this.scene.get('MainScene').myMineLevel[1] >= 2 && this.scene.get('MainScene').myMineLevel[2] >= 2) {
		} else {
			var bannerContainer1 = this.add.container();
			var banner01 = this.add.image(0, 0, 'shop-banner-1').setOrigin(0.5, 0.5);
			bannerContainer1.setSize(banner01.width, banner01.height);
			bannerContainer1.add(banner01);
			var banner1Price = this.add.text(0 - banner01.width / 2 + 40, 80, shopItemData.offers[0].price, AppController.getText(50)).setOrigin(0, 0.5);
			banner1Price.setStroke('#000000', 5);
			const gradient = banner1Price.context.createLinearGradient(0, 0, 0, banner1Price.height);
			gradient.addColorStop(0, '#e0892f');
			gradient.addColorStop(1, '#ffe96c');
			banner1Price.setFill(gradient);

			bannerContainer1.add(banner1Price);

			var earn_icon1 = this.add.image(banner1Price.x + banner1Price.width + 10, banner1Price.y, 'main2', 'paw_token_1').setOrigin(0, 0.5);
			bannerContainer1.add(earn_icon1);
			bannerContainer1.inappData = shopItemData.offers[0];
			container.add(bannerContainer1);
		}

		if (this.scene.get('MainScene').myMineLevel[0] >= 18 && this.scene.get('MainScene').myMineLevel[1] >= 4 && this.scene.get('MainScene').myMineLevel[2] >= 5) {
			var emptyTitle = this.add.text(0, 0, 'No offer at this time.', AppController.getText(40)).setOrigin(0.5, 0.5);
			container.add(emptyTitle);
		} else {
			var bannerContainer2 = this.add.container();
			var banner02 = this.add.image(0, 0, 'shop-banner-2').setOrigin(0.5, 0.5);
			bannerContainer2.setSize(banner02.width, banner02.height);
			bannerContainer2.add(banner02);
			var banner2Price = this.add.text(0 - banner02.width / 2 + 40, 80, shopItemData.offers[1].price, AppController.getText(50)).setOrigin(0, 0.5);
			banner2Price.setStroke('#000000', 5);

			const gradient2 = banner2Price.context.createLinearGradient(0, 0, 0, banner2Price.height);
			gradient2.addColorStop(0, '#e0892f');
			gradient2.addColorStop(1, '#ffe96c');

			banner2Price.setFill(gradient2);

			bannerContainer2.add(banner2Price);

			var earn_icon2 = this.add.image(banner2Price.x + banner2Price.width + 10, banner2Price.y, 'main2', 'paw_token_1').setOrigin(0, 0.5);
			bannerContainer2.add(earn_icon2);
			bannerContainer2.inappData = shopItemData.offers[1];
			container.add(bannerContainer2);
		}

		var titleContainer2 = this.add.container();
		var titlePawStoreBg = this.add.nineslice(0, 0, 'main2', 'shop_title_bg', 660, 0, 12, 12, 0, 0).setOrigin(0.5, 0.5);
		titleContainer2.add(titlePawStoreBg);
		var titlePawShopTitle = this.add.text(titlePawStoreBg.x, titlePawStoreBg.y, 'PAW Store', AppController.getText(50)).setOrigin(0.5, 0.5);
		titleContainer2.add(titlePawShopTitle);
		titleContainer2.setSize(titlePawStoreBg.width, titlePawStoreBg.height);
		container.add(titleContainer2);

		var childPanel = this.rexUI.add.gridSizer({
			x: 360,
			y: 1300,
			column: 2,
			row: 4,
			//orientation: 'y',
			space: { left: 30, right: 30, column: 30, row: 60, bottom: 50 },
		});

		console.log(shopItemData);

		/*shopItemData.data.unshift({
			badge: 'Daily Free',
			ton_price: 100,
			thumb: 'shop_thumb_08',
			price: 100,
			name: '12 Equips',
			id: 'dailyShop',
			label: 'Claim',
			value: 0,
		});*/

		var shopItemArr = [];

		for (var i = 0; i < shopItemData.data.length; i++) {
			var child = this.createItemRenderer(i + 1, shopItemData.data[i]);
			shopItemArr.push(child);
			childPanel.add(child);
		}
		childPanel.layout();
		container.add(childPanel);
		childPanel.setChildrenInteractive().on('child.click', async function(child) {
			console.log(child);
		});

		//var abc = this.createItemRenderer();
		//childPanel.removeInteractive();

		var config = {
			x: popupBG.x,
			y: popupBG.y,
			width: popupBG.width,
			height: popupBG.height - 110,
			scrollMode: 0,
			slider: false,
			panel: {
				child: container,
				mask: {
					padding: 0,
				},
			},
		};

		var panel = this.rexUI.add
			.scrollablePanel(config)
			.layout()
			.setOrigin(0.5, 0.5);

		panel.setChildrenInteractive().on('child.click', async function(child, pointer, event) {
			var isTouching = childPanel.isInTouching();

			if (isTouching) {
				for (var i = 0; i < shopItemArr.length; i++) {
					if (childPanel.isInTouching(shopItemArr[i])) {
						if (shopItemArr[i].inappData.id == 'dailyShop') {
							if (child.scene.scene.get('MainScene').myCharacterData.dailyShop != true) {
							} else {
								child.scene.scene.launch('LockScreen');

								var checkinResult = await AppController.checkInTon();

								console.log(checkinResult);

								if (checkinResult.success == false) {
									child.scene.scene.stop('LockScreen');
									child.scene.scene.launch('Alert', { mess: 'Transaction not sent!' });
									return;
								}

								const levelUpResult = await AppController.dailyShopReward();
								child.scene.scene.stop('LockScreen');

								var remainingFree = levelUpResult.data.airdrop.length;

								for (var i = 0; i < levelUpResult.data.airdrop.length; i++) {
									if (levelUpResult.data.airdrop[i] != 0) {
										break;
									}
									remainingFree -= 1;
								}
								child.scene.scene.get('MainScene').myCharacterData.dailyShop = false;
								child.scene.scene.get('MainScene').mutantPriceText.text = 'Free (' + remainingFree + '/' + levelUpResult.data.airdrop.length + ')';
								child.scene.scene.stop('ShopPopup');
							}

							return;
						}

						var newPopupScene = this.scene.scene.launch('PurchasePopup', shopItemArr[i].inappData);
						break;
					}
				}
			} else {
				if (child.inappData.id == 'dog_warrior' || child.inappData.id == 'super_dog') {
					var confirmFunction = async function() {
						child.scene.scene.launch('LockScreen');
						const levelUpResult = await AppController.buyOffers(child.inappData.id);
						child.scene.scene.stop('LockScreen');

						if (levelUpResult.data.success == false) {
							child.scene.scene.launch('Alert', { mess: levelUpResult.data.mess });
						} else {
							child.scene.scene.get('MainScene').pawSlot.updateNumber(levelUpResult.data.paw);
							//{ mineData: mineData, myMineLevel: myMineLevel, paw: myPaw };

							var myMaxStamina = levelUpResult.data.mineData[0].level[levelUpResult.data.myMineLevel[0]].value;
							var myPowerUpMultiplider = levelUpResult.data.mineData[2].level[levelUpResult.data.myMineLevel[2]].value;

							child.scene.scene.get('MainScene').staminaSlot.maxNumber = myMaxStamina;
							child.scene.scene.get('MainScene').staminaSlot.updateNumber(child.scene.scene.get('MainScene').staminaSlot.currentNumber);
							child.scene.scene.get('MainScene').powerUpButton.text = 'x' + myPowerUpMultiplider;

							var remainingFree = levelUpResult.data.airdrop.length;

							for (var i = 0; i < levelUpResult.data.airdrop.length; i++) {
								if (levelUpResult.data.airdrop[i] != 0) {
									break;
								}
								remainingFree -= 1;
							}

							child.scene.scene.get('MainScene').mutantPriceText.text = 'Free (' + remainingFree + '/' + levelUpResult.data.airdrop.length + ')';
							child.scene.scene.stop('ShopPopup');
						}
					};

					this.scene.scene.launch('Alert', { mess: 'Do you want to buy ' + child.inappData.name + ' package with ' + child.inappData.price + ' $PAW?', label: 'Confirm', confirmFunction: confirmFunction });
				}
			}

			return;
			if (this.isDisable == true) {
				console.log('isDisable');
				return;
			}
			if (child.inappData.id == 'inapp1') {
				console.log('inapp1');
				return;
			}

			var newPopupScene = this.scene.scene.launch('PurchasePopup', child.inappData);
			return;
		});

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			this.scene.scene.stop('ShopPopup');
		});

		//
	}

	async buyProduct(productId) {}

	createItemRenderer(index, data) {
		var container = this.add.container();
		container.inappData = data;

		var itemBg = this.add.nineslice(0, 0, 'main2', 'earn_button_none', 260, 250, 3, 3, 3, 3);
		container.add(itemBg);
		container.setSize(itemBg.width, itemBg.height);
		//container.setInteractive();

		var light = this.add.image(itemBg.x, itemBg.y - 30, 'main2', 'shop_light_fx').setScale(2, 2);
		container.add(light);

		var icon = this.add.image(light.x, light.y, 'main2', data.thumb);
		container.add(icon);

		var textBanner = this.add.rectangle(0, 50, itemBg.width, 50, 0x011e45, 0.8).setOrigin();
		container.add(textBanner);

		var name = this.add.text(textBanner.x, textBanner.y, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: '#fff200', align: 'left' }).setOrigin(0.5, 0.5);
		container.add(name);

		var buttonName = 'button_green';
		if (data.id == 'dailyShop') {
			buttonName = 'button_blue';
		}

		var buyButton = new PopupButton(this, 0, itemBg.height / 2, buttonName, data.label);

		if (data.id == 'dailyShop' && this.scene.get('MainScene').myCharacterData.dailyShop != true) {
			buyButton.disableBtn = true;
		}

		//buyButton.setInteractive();
		container.button = buyButton;
		buyButton.on('pointerup', event => {
			//
		});

		if (data.hasOwnProperty('badge')) {
			var badgeBg = this.add.image(-itemBg.width / 2 + 40, -itemBg.height / 2 + 40, 'main2', 'sale_badge_orange');
			container.add(badgeBg);
			var badgeTxt = this.add.text(badgeBg.x, badgeBg.y, data.badge, { wordWrap: { width: badgeBg.width }, fontFamily: 'Barlow Condensed', fontSize: '25px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);
			badgeTxt.angle = -25;

			container.add(badgeTxt);
		}

		/*var indexTxt = this.add.text(-250, 0, index, { fontFamily: 'Barlow Condensed', fontSize: '40px', fill: 'white', align: 'center' }).setOrigin(0.5, 0.5);

		if (index < 4) {
			var medal = this.add.image(indexTxt.x, indexTxt.y, 'main2', 'medal_' + index);
			container.add(medal);
		}

		var name = this.add.text(-150, -38, data.name, { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'left' }).setOrigin(0, 0);
		var elo = this.add.text(-150, -5, 'Elo: ' + data.elo.toString(), { fontFamily: 'Barlow Condensed', fontSize: '35px', fill: 'yellow', align: 'left' }).setOrigin(0, 0);

		container.add(name);
		container.add(elo);
		//
		container.add(indexTxt);*/

		container.add(buyButton);

		return container;
	}
}
