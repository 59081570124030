export default class ChooseCharacterButton extends Phaser.GameObjects.Container {
	static currentSelectedBtn;
	constructor(scene, x, y, name, atlas = 'main2') {
		super(scene, x, y);
		this.btnName = name;
		scene.add.existing(this);
		this.bg = scene.add.image(0, 0, 'main2', 'character_normal');
		this.icon = scene.add.image(0, 0, 'main2', 'character_' + name);

		this.setSize(this.bg.width, this.bg.height);
		this.setInteractive();

		this.add(this.bg);
		this.add(this.icon);

		this.on('pointerover', function() {});

		this.on('pointerdown', function() {
			this.setSelected();
		});

		this.on('pointerout', function() {});

		this.on('pointerup', function() {
			this.bg.setScale(1, 1);
		});
	}

	setSelected() {
		if (ChooseCharacterButton.currentSelectedBtn != null) {
			ChooseCharacterButton.currentSelectedBtn.removeSelected();
		}
		this.selected = true;
		this.bg.setTexture('main2', 'character_active');
		ChooseCharacterButton.currentSelectedBtn = this;
	}
	removeSelected() {
		this.bg.setTexture('main2', 'character_normal');
	}
}
