import Phaser from 'phaser';
import PopupButton from '../components/PopupButton';
import SimpleImageButton from '../components/SimpleImageButton';
import AppController from '../controller/AppController';
export default class Alert extends Phaser.Scene {
	constructor() {
		super('Alert');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'Alert' });
	}

	async init(data) {
		this.itemData = data;
	}

	preload() {}

	create() {
		console.log('alert');
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '40px', wordWrap: { width: 500, useAdvancedWrap: true }, fill: 'white', align: 'center' };
		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 640, 720, 1280, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var popupBG = this.add.nineslice(360, 640, 'main2', 'popup_bg', 550, 400, 40, 40, 40, 50);
		popupBG.setInteractive();

		var titleBg = this.add.nineslice(popupBG.x, popupBG.y - popupBG.height / 2, 'main2', 'popup_title_bg', popupBG.width - 140, 0, 63, 63, 0, 0);
		var panelTitle = this.add.text(titleBg.x, titleBg.y, 'Alert', AppController.getText(40)).setOrigin(0.5, 0.5);

		var closeButton = new SimpleImageButton(this, titleBg.x + titleBg.width / 2, titleBg.y, 'bt_close');
		closeButton.on('pointerup', function() {
			console.log(this.scene.itemData);

			if (this.scene.itemData.isBackend) {
				AppController.clearAlert();
			}
			this.scene.scene.stop('Alert');
		});

		var alertContent = this.add.text(popupBG.x, popupBG.y, this.itemData.mess, LABEL_TEXT_CONFIG).setOrigin(0.5, 0.5);

		if (this.itemData.label != null) {
			var closeButtonBottom = new PopupButton(this, popupBG.x, popupBG.y + popupBG.height / 2 - 70, 'button_blue', this.itemData.label);

			closeButtonBottom.on('pointerup', function() {
				if (this.scene.itemData.url != null) {
					Telegram.WebApp.openLink(this.scene.itemData.url, { try_instant_view: true });
				}

				if (this.scene.itemData.confirmFunction != null) {
					this.scene.itemData.confirmFunction();
				}

				this.scene.scene.stop('Alert');
			});
		}
	}
}
