import Phaser from 'phaser';
import AppController from '../controller/AppController';
export default class ResultPopup extends Phaser.Scene {
	constructor() {
		super('ResultPopup');
	}

	initialize() {
		Phaser.Scene.call(this, { key: 'ResultPopup' });
	}

	async init(data) {
		console.log(data);
		this.resultData = data;
	}

	preload() {}

	async create() {
		const ATTRIBUTE_SPACE = 300;
		const LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontSize: '30px', fill: 'white', align: 'center' };
		const BIG_LABEL_TEXT_CONFIG = { fontFamily: 'Barlow Condensed', fontStyle: 'bold', fontSize: '50px', fill: 'white', align: 'center' };

		const LABEL_ROW_SPACE = 40;

		var r1 = this.add.rectangle(360, 380, 720, 256, 0x000000, 0.5).setOrigin();
		r1.setInteractive();

		var textTitle = 'YOU WIN';
		var textColor = '#00ff00';
		if (this.resultData.isWin == false) {
			var textTitle = 'YOU LOSE';
			var textColor = '#ff161f';
		}

		var text = this.add.text(-100, r1.y - 50, textTitle, AppController.getText(70, textColor, 'bold')).setOrigin(0.5, 0.5);
		var icon = this.add.image(r1.x - 300, r1.y + 50, 'main2', 'token_energy').setOrigin(0.2, 0.5);
		this.earnTxt = this.add.text(icon.x + icon.width, icon.y, '+' + AppController.formatNumber(this.resultData.earn), AppController.getText(50, '#fff', 'bold')).setOrigin(0, 0.5);
		this.eloTxt = this.add.text(this.earnTxt.x + 300, icon.y, 'Elo: ' + this.resultData.eloIncrease, AppController.getText(50, textColor, 'bold')).setOrigin(0, 0.5);

		this.tweens.add({
			targets: text,
			x: r1.x,
			ease: 'Power2',
			duration: 500,
		});

		this.tweens.addCounter({
			from: 0,
			to: this.resultData.earn,
			duration: 500,
			ease: 'linear',
			onUpdate: tween => {
				const value = Math.round(tween.getValue());
				this.earnTxt.setText(`+${AppController.formatNumber(value)}`);
			},
		});
	}
}
